.layout-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.layout-background {
    background-position: center;
    background-size: cover;
    background-repeat: 'no-repeat';
    width: 100vw;
    height: 100vh;
}

.logo-fixed {
    position: absolute;
    left: 150px;
    top: 50px;
}