.card-camp {
    width: auto;
    position: relative;
  
    .card-img {
      background: lightgrey;
      height: 250px;
      border-radius: 8px;
      position: relative;
  
      .card-img-menu {
        position: absolute;
        display: none;
        bottom: 0;
        background: #FFD400;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        font-size: 18px;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px;
      }

      .card-img-menu-item {
        display: flex;
        column-gap: 16px;
        cursor: pointer;
      }

    }
  
    .card-title {
      margin-top: 12px;
      text-align: center;
      font-size: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    &.selected {
        .card-img-menu {
            display: flex;
        }
    }
  }

  .cards-camp {
    display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      column-gap: 12px;
      row-gap: 24px;
  }

  .cards-scroll {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .card-select {
    position: absolute;
    top: 0;
    right: 16px;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
  }