.ant-btn {
    font-weight: 500;
}

// .ant-btn.ant-btn-lg {
//     padding: 4px 48px;
// }

// .ant-checkbox .ant-checkbox-inner {
//     width: 20px;
//     height: 20px;
// }
