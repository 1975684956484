.chart {
    display: grid;
    gap: 0 20px;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    min-height: 250px;
    padding-bottom: 12px;
    padding-inline: 24px;
    

    li {
      height: 100%;
      border-radius: 8px;
      position:relative;
    }
    
    li span {
      font-weight: bold;
      position: absolute;
      bottom: -40px;
      font-size: 10px;
      width: 100%;
      text-align: center;
    }
  }

  .chart-wrapper {
    margin-bottom: 32px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      display: block;
      background: #0D0584;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      display: block;
      background: #0D0584;
    }
  }