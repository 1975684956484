.card {
    width: auto;
    position: relative;
  
    .card-img {
      background: lightgrey;
      height: 250px;
      border-radius: 8px;
      position: relative;
  
      .card-img-btn {
        position: absolute;
        display: none;
        bottom: 8px;
        left: calc(50% - 78px);
        padding: 8px 48px;
      }
  
      &:hover {
        .card-img-btn {
          display: block;
        }
      }
    }
  
    .card-title {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
    }
  }

  .cards {
    display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      column-gap: 20px;
  }

  .cards-scroll {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .card-select {
    position: absolute;
    top: 0;
    right: 16px;
  }