.footer {
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid rgba(204, 204, 204, 0.432);
  padding: 16px 0;
}
