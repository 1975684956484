
.trigger {
    color: white;

}

.trigger-wrapper {
    position: absolute;
    right: -20px;
    top: 60px;
    background-color: #00137f; 
    width: 20px;
    height: 60px;
    display: flex;
    border-radius: 0px 12px 12px 0px
}

.sider {

    &.ant-layout-sider {
        width: 250px;
        min-width: 250px;
        //position: relative;
    }
    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        row-gap: 120px;
    }
} 

.logo-sider {
    align-self: center;
    margin-top: 32px;
}
