.help-card {
    display: flex;
    justify-content: space-between;
    margin-inline: 200px;
    margin-bottom: 50px;
}

.help-card-section {
    display: flex;
    column-gap: 50px;
}